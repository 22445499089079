export const actionTypes = {
  GET_LIST_START: 'tsk-GET_LIST_START',
  GET_LIST_SUCCESS: 'tsk-GET_LIST_SUCCESS',
  GET_LIST_FAILURE: 'tsk-GET_LIST_FAILURE',

  FULFILL_START: 'tsk-FULFILL_START',
  FULFILL_SUCCESS: 'tsk-FULFILL_SUCCESS',
  FULFILL_FAILURE: 'tsk-FULFILL_FAILURE',

  UPDATE_START: 'tsk-UPDATE_START',
  UPDATE_SUCCESS: 'tsk-UPDATE_SUCCESS',
  UPDATE_FAILURE: 'tsk-UPDATE_FAILURE',

  ASSIGN_START: 'tsk-ASSIGN_START',
  ASSIGN_SUCCESS: 'tsk-ASSIGN_SUCCESS',
  ASSIGN_FAILURE: 'tsk-ASSIGN_FAILURE',

  GET_ASSIGNABLE_USERS_START: 'tsk-GET_ASSIGNABLE_USERS_START',
  GET_ASSIGNABLE_USERS_SUCCESS: 'tsk-GET_ASSIGNABLE_USERS_SUCCESS',
  GET_ASSIGNABLE_USERS_FAILURE: 'tsk-GET_ASSIGNABLE_USERS_SUCCESS',

  S3_UPLOAD_START: 'tsk-S3_UPLOAD_START',
  S3_UPLOAD_SUCCESS: 'tsk-S3_UPLOAD_SUCCESS',
  S3_UPLOAD_PROGRESS: 'tsk-S3_UPLOAD_PROGRESS',
  S3_UPLOAD_FAILURE: 'tsk-S3_UPLOAD_FAILURE',
  S3_UPLOAD_RESET: 'tsk-S3_UPLOAD_RESET',

  S3_UPLOAD_TOGGLE_BACKGROUND: 'tsk-S3_UPLOAD_TOGGLE_BACKGROUND',

  GET_TABLE_PREFERENCES_SUCCESS: 'tsk-GET_TABLE_PREFERENCES_SUCCESS',
  RESET_TABLE_PREFERENCES: 'tsk-RESET_TABLE_PREFERENCES',
  SET_TABLE_PREFERENCES: 'tsk-SET_TABLE_PREFERENCES',
  OPEN_USER_INPUT_TASK_MODAL: 'tsk-OPEN_USER_INPUT_TASK_MODAL',
  CLOSE_USER_INPUT_TASK_MODAL: 'tsk-CLOSE_USER_INPUT_TASK_MODAL',
};

export const getListStart = () => ({
  type: actionTypes.GET_LIST_START,
});

export const getListSuccess = result => ({
  type: actionTypes.GET_LIST_SUCCESS,
  payload: result,
});

export const getListFailure = () => ({
  type: actionTypes.GET_LIST_FAILURE,
});

export const getAssignableUsersStart = () => ({
  type: actionTypes.GET_ASSIGNABLE_USERS_START,
});

export const getAssignableUsersSuccess = result => ({
  type: actionTypes.GET_ASSIGNABLE_USERS_SUCCESS,
  payload: result,
});

export const getAssignableUsersFailure = () => ({
  type: actionTypes.GET_ASSIGNABLE_USERS_SUCCESS,
});

export const fulfillTaskStart = (result) => ({
  type: actionTypes.FULFILL_START,
  payload: result,
});
export const fulfillTaskSuccess = (updatedTask) => ({
  type: actionTypes.FULFILL_SUCCESS,
  payload: updatedTask,
});
export const fulfillTaskFailure = () => ({
  type: actionTypes.FULFILL_FAILURE,
});

export const updateTaskStart = (data) => ({
  type: actionTypes.UPDATE_START,
  payload: data,
});
export const updateTaskSuccess = () => ({
  type: actionTypes.UPDATE_SUCCESS,
});
export const updateTaskFailure = () => ({
  type: actionTypes.UPDATE_FAILURE,
});

export const assignTaskStart = data => ({
  type: actionTypes.ASSIGN_START,
  payload: data,
});

export const assignTaskSuccess = data => ({
  type: actionTypes.ASSIGN_SUCCESS,
  payload: data,
});

export const assignTaskFailure = () => ({
  type: actionTypes.ASSIGN_FAILURE,
});

export const getTablePreferencesSuccess = result => ({ type: actionTypes.GET_TABLE_PREFERENCES_SUCCESS, payload: result });

export const resetTablePreferences = () => ({ type: actionTypes.RESET_TABLE_PREFERENCES });

export const setTablePreferences = payload => ({ type: actionTypes.SET_TABLE_PREFERENCES, payload });

export const openUserInputTaskModal = () => ({ type: actionTypes.OPEN_USER_INPUT_TASK_MODAL });

export const closeUserInputTaskModal = () => ({ type: actionTypes.CLOSE_USER_INPUT_TASK_MODAL });

const tasks = {
  getListStart,
  getListSuccess,
  getListFailure,
  fulfillTaskStart,
  fulfillTaskSuccess,
  fulfillTaskFailure,
  assignTaskStart,
  assignTaskSuccess,
  assignTaskFailure,
  getTablePreferencesSuccess,
  resetTablePreferences,
  setTablePreferences,
  openUserInputTaskModal,
  closeUserInputTaskModal,
};

export default tasks;
