import moment from 'moment';
import last from 'lodash/last';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import omit from 'lodash/omit';
import has from 'lodash/has';
import getConfig from 'next/config';
import flatten from 'flat';
import { intersection, isBoolean } from 'lodash';
import { SUBMISSION_GENRE, CONTENT_DELIVERY_TYPES, RATINGS, RATING_NO_PREFERENCE, WORK_ORDER_TAG_DICTIONARY, BOOL_TO_TEXT, COMPLIANCE_REVIEW_PASSED_TAGS, PRODUCT_PATH_GENERATE_RATING_FROM_CINEMA, ZERO_VALUE_ALLOWED_FIELDS } from './constants';
import { changeCaseConstantToSentence, escapeQuotationMarks } from './utils';
import { displayFormatDate, secondsToMinutes } from './date';

const EIDR_REGEXP = /10\.5240\/([0-9A-Z]{4}-){5}[0-9A-Z]$/;
const MAX_RUNTIME = 480;
const { publicRuntimeConfig: { deadlineHours } } = getConfig();

const DEFAULT_REQUIRED_FIELDS = [
  'title',
  'requiredRating',
  'director',
  'year',
  'country',
  'language',
  'runtimeInSeconds',
];

const IGNORED_META_FIELDS = ['productParams'];

const IGNORED_DUAL_RATING_META_FIELDS = [
  'isDualRatingCriteriaAllowed',
  'cinemaRegistrationNumber',
  ...IGNORED_META_FIELDS,
];

const IGNORED_GENERATE_RATING_FROM_CINEMA_META_FIELDS = [
  'requiredRating',
  ...IGNORED_META_FIELDS,
];

export const humanizeFillingDuration = (timekeeper) => {
  try {
    const startTime = timekeeper.find(t => t.flag === 'start').time;
    const finishTime = timekeeper.find(t => t.flag === 'finish').time;
    return moment.duration(finishTime - startTime).humanize();
  } catch (error) {
    return 'N/A';
  }
};

export const getGenreLabel = genre => {
  switch (genre) {
    case SUBMISSION_GENRE.ACTION: return 'Action';
    case SUBMISSION_GENRE.ADDITIONAL_PRODUCTION_MATERIAL: return 'Additional Production Material';
    case SUBMISSION_GENRE.ADULT: return 'Adult';
    case SUBMISSION_GENRE.ADVENTURE: return 'Adventure';
    case SUBMISSION_GENRE.ANIMATION: return 'Animation';
    case SUBMISSION_GENRE.CHILDREN: return 'Children';
    case SUBMISSION_GENRE.COMEDY: return 'Comedy';
    case SUBMISSION_GENRE.CRIME: return 'Crime';
    case SUBMISSION_GENRE.DOCUMENTARY: return 'Documentary';
    case SUBMISSION_GENRE.DRAMA: return 'Drama';
    case SUBMISSION_GENRE.EDUCATION: return 'Education';
    case SUBMISSION_GENRE.FANTASY: return 'Fantasy';
    case SUBMISSION_GENRE.FILM_NOIR: return 'Film Noir';
    case SUBMISSION_GENRE.GAY_ADULT: return 'Gay Adult';
    case SUBMISSION_GENRE.HORROR: return 'Horror';
    case SUBMISSION_GENRE.MARTIAL_ARTS: return 'Martial Arts';
    case SUBMISSION_GENRE.MUSIC: return 'Music';
    case SUBMISSION_GENRE.MUSICAL: return 'Musical';
    case SUBMISSION_GENRE.MYSTERY: return 'Mystery';
    case SUBMISSION_GENRE.PERFORMANCE: return 'Performance';
    case SUBMISSION_GENRE.REALITY: return 'Reality';
    case SUBMISSION_GENRE.ROMANCE: return 'Romance';
    case SUBMISSION_GENRE.SCIENCE_FICTION: return 'Science Fiction';
    case SUBMISSION_GENRE.SPORT: return 'Sport';
    case SUBMISSION_GENRE.THRILLER: return 'Thriller';
    case SUBMISSION_GENRE.WAR: return 'War';
    case SUBMISSION_GENRE.WESTERN: return 'Western';
    case SUBMISSION_GENRE.OTHER: return 'Other';
    default: return genre;
  }
};

// can be used for validating is any data provided before saving like a draft
export const isValidMinimalMetaData = (meta) => Boolean(
  meta.title ||
  meta.director ||
  meta.year ||
  meta.country.length ||
  meta.genre ||
  meta.date ||
  meta.runtimeInSeconds ||
  meta.type
);

export const isSameDay = (dateA, dateB) => moment(dateA).endOf('day').format('L') === moment(dateB).endOf('day').format('L');

export const setDeadlineTime = (date, fieldName) => moment(date)
  .utc()
  .set('hours', deadlineHours[fieldName])
  .set('minutes', 0)
  .set('seconds', 0)
  .set('milliseconds', 0);

export const setTimeFrom = (date, timeFromMax) => {
  const donor = moment(timeFromMax);
  const receiver = moment(date).utc();
  const hour = donor.hour();
  const minute = donor.minute();
  const utcOffset = donor.utcOffset();
  return receiver.utcOffset(utcOffset).set({
    hour,
    minute,
    second: 0,
    millisecond: 0,
  });
};

export const getDefaultClassificationDueDate = () => setDeadlineTime(moment().add(28, 'days'), 'requiredClassificationDueDate');

export const isGenreValid = meta => {
  if ([RATINGS.RATING_R18].includes(meta.requiredRating) && !(meta.genre || []).includes('ADULT')) return false;
  return true;
};

export const isRequiredRatingValid = meta => {
  if ((meta.genre || []).includes('ADULT') && ![RATINGS.RATING_R18, RATINGS.RATING_18, RATING_NO_PREFERENCE].includes(meta.requiredRating)) return false;
  return true;
};

export const isReleaseDateValid = (releaseDate, rcd) => {
  const minReleaseDate = moment(rcd).add(1, 'day');
  if (moment(releaseDate).isBefore(minReleaseDate, 'day')) return false;
  return true;
};

export const isEidrValid = eidr => Boolean(eidr && eidr.length && EIDR_REGEXP.test(eidr));

const isValidGeneralConditionalField = (fieldName, meta, fieldSet) => (fieldSet[fieldName] && meta[fieldName]) || !fieldSet[fieldName];

export const isValidFieldValue = (fieldName, value) => {
  if (ZERO_VALUE_ALLOWED_FIELDS.includes(fieldName)) {
    return value !== '';
  }
  return value !== 0;
};

const isValidField = (fieldName, meta, fieldSet) => {
  if (!fieldSet[fieldName]) return true;
  if (
    meta.priceVersion !== 2 &&
    [
      'videoIdentical',
      'preschool',
      'modernFilmClassification',
      'numberOfLocations',
    ].includes(fieldName)) return true;

  if (fieldSet[fieldName] === 'required'
    && typeof meta[fieldName] !== 'undefined'
    && isValidFieldValue(fieldName, meta[fieldName])
    && meta[fieldName] !== null
  ) {
    return true;
  }
  if (fieldSet[fieldName] === 'optional') return true;
  if (fieldSet[fieldName] === 'N/A') return true;
  return false;
};

export const isValidFieldAndNotFalse = (fieldName, meta, fieldSet) => {
  const isValid = isValidField(fieldName, meta, fieldSet);
  if (isValid && (fieldSet[fieldName] === 'required' && meta[fieldName] === false)) {
    return false;
  }
  return isValid;
};

export const isValidFieldAndNotEmpty = (fieldName, meta, fieldSet) => {
  const isValid = isValidField(fieldName, meta, fieldSet);
  if (isValid && (fieldSet[fieldName] === 'required' && meta[fieldName] === '')) {
    return false;
  }
  return isValid;
};

export const isValidFieldAndHasItems = (fieldName, meta, fieldSet) => {
  const isValid = isValidField(fieldName, meta, fieldSet);
  if (isValid && (fieldSet[fieldName] === 'required' && (meta[fieldName] || []).length === 0)) {
    return false;
  }
  return isValid;
};

export const isEpisodic = productParams => productParams && productParams.input && productParams.input.addEpisodeInfo;

export const isInvoicingCompanyValid = invoicingCompany => invoicingCompany !== 'null';

export const splitProductCategory = stringValue => (stringValue || '').split('/');

export const getContentDeliveryTypeFromProductPath = productPath => {
  const contentDeliveryType = last(splitProductCategory(productPath)).toLowerCase();
  if (contentDeliveryType === 'link') {
    return CONTENT_DELIVERY_TYPES.URL;
  }
  return Object.values(CONTENT_DELIVERY_TYPES).includes(contentDeliveryType) ? contentDeliveryType : CONTENT_DELIVERY_TYPES.NONE;
};

export const getMinClassificationDay = (meta, minClassificationDays) => {
  const productType = getContentDeliveryTypeFromProductPath(meta.productPath);
  return moment(minClassificationDays[productType] || minClassificationDays.other);
};

export const getRCDValidationError = (requiredClassificationDueDate, meta, { minClassificationDays = {} } = {}) => {
  if (!requiredClassificationDueDate) {
    return `${get(meta, 'productParams.label.requestedClassificationDate', 'Requested Classification Date')} is required`;
  }
  const minDate = getMinClassificationDay(meta, minClassificationDays);
  if (minDate.isAfter(requiredClassificationDueDate)) {
    return 'Date is too early';
  }
  if (
    meta?.releaseDate && (
      isSameDay(meta.releaseDate, meta.requiredClassificationDueDate) ||
      moment(meta.releaseDate).isBefore(meta.requiredClassificationDueDate)
    )
  ) {
    return 'Should be before the release date';
  }
  return undefined;
};

const getRuntimeValidationError = (meta) => {
  const runtime = secondsToMinutes(meta.runtimeInSeconds);
  if (!runtime || Number.isNaN(runtime) || runtime <= 0) return 'Runtime is required';
  if (!Number.isInteger(runtime)) return 'Runtime must be an integer';
  const maxRuntime = get(meta, 'productParams.input.maximumRuntime', MAX_RUNTIME);

  if (runtime > maxRuntime) return `Runtime must be below ${maxRuntime} minutes`;
  return undefined;
};

export const isValidEpisodeMetaData = (meta, { fieldSet = {} } = {}) => {
  const validationItems = [
    isValidField('episodeTitle', meta, fieldSet),
    isValidField('episode', meta, fieldSet),
    meta.director,
    //isValidGeneralConditionalField('version', meta, fieldSet),
    !getRuntimeValidationError(meta),
  ];

  if (validationItems.every(Boolean)) {
    return true;
  }

  return false;
};

export const isValidBonusMaterialMetaData = (meta, { fieldSet = {} } = {}) => {
  const validationItems = [
    isValidField('additionalTitle', meta, fieldSet),
    !getRuntimeValidationError(meta),
  ];

  if (validationItems.every(Boolean)) {
    return true;
  }

  return false;
};

const isRequiredField = (fieldName, { fieldSet }) => fieldSet[fieldName] === 'required' || DEFAULT_REQUIRED_FIELDS.includes(fieldName);

export const isValidMetaData = (meta, options, { fieldSet = {}, minClassificationDays = {}, calendars = {} } = {}) => {
  const validationItems = [
    meta.productPath,
    meta.contentDeliveryType,
    isInvoicingCompanyValid(meta.invoicingCompany),
    meta.title,
    meta.year,
    (meta.country && meta.country.length),
    meta.language,
    isValidFieldAndHasItems('genre', meta, fieldSet),
    isValidField('season', meta, fieldSet),
    isValidField('seasonTitle', meta, fieldSet),
    isGenreValid(meta),
    isValidField('numberOfLocations', meta, fieldSet),
    isValidField('videoIdentical', meta, fieldSet),
    isValidField('aspectRatio', meta, fieldSet),
    isValidField('preschool', meta, fieldSet),
    isValidField('modernFilmClassification', meta, fieldSet),
    isValidField('compositeElements', meta, fieldSet),
    isValidField('alsoKnownAs', meta, fieldSet),
    isValidFieldAndNotFalse('isDualRatingCriteriaAllowed', meta, fieldSet),
    isValidFieldAndNotEmpty('cinemaRegistrationNumber', meta, fieldSet),
  ];

  if (!options?.withoutRCD) {
    validationItems.push(!getRCDValidationError(meta.requiredClassificationDueDate, meta, { minClassificationDays }));
  }

  if (!options?.excludeRebookingFields) {
    if (isRequiredField('releaseDate', { fieldSet }) || meta.releaseDate) {
      validationItems.push(isValidField('releaseDate', meta, fieldSet));
      validationItems.push(isReleaseDateValid(meta.releaseDate, meta.requestedClassificationDueDate));
    }
  }

  // Put validation rules back for non-batch submissions
  if (!meta?.productParams?.batch) {
    const validation = [
      isValidField('director', meta, fieldSet),
      isValidGeneralConditionalField('version', meta, fieldSet),
      !getRuntimeValidationError(meta, calendars),
    ];
    validationItems.push(...validation);
  }

  if (validationItems.every(Boolean)) {
    return true;
  }

  return false;
};

export const isValidAllEpisodeMetaData = (episodes = [], { fieldSet = {} } = {}) => {
  const { addEpisodeInfo } = fieldSet;

  if (addEpisodeInfo && !episodes.length) return false;

  const episodeValidations = episodes.map((e) => isValidEpisodeMetaData(e, { fieldSet }));
  const isAllValid = episodeValidations.every(e => e);

  return isAllValid;
};

export const isValidAllItemsMetaData = (items = [], { productPath, fieldSet = {} } = {}) => {
  if (productPath.includes('Episodic')) {
    const { addEpisodeInfo } = fieldSet;

    if (addEpisodeInfo && !items.length) return false;

    const validations = items.map((e) => isValidEpisodeMetaData(e, { fieldSet }));
    const isAllValid = validations.every(e => e);

    return isAllValid;
  }
  if (productPath.includes('Bonus Material')) {
    const validations = items.map((e) => isValidBonusMaterialMetaData(e, { fieldSet }));
    const isAllValid = validations.every(e => e);

    return isAllValid;
  }
  return true;
};

export const isValidItemsTotalRuntime = (items = [], viewingCalendar = null) => {
  if (!viewingCalendar) return true;
  const tallyRuntimeInSeconds = items.reduce((tally, item) => tally + item.runtimeInSeconds, 0);
  const maxMinutes = Math.max(viewingCalendar.standard, viewingCalendar.next_day, viewingCalendar.express);
  return (tallyRuntimeInSeconds / 60) <= maxMinutes;
};

export const metaValidation = (meta = {}, { fieldSet = {}, minClassificationDays = {}, updatableMetaFields = [], calendars = {} } = {}, options = {}) => {
  const errors = {
    productPath: !meta.productPath || !meta.contentDeliveryType ? 'Service category is required' : undefined,
    title: !meta.title ? 'Title is required' : undefined,
    director: !isValidField('director', meta, fieldSet) ? 'Director is required' : undefined,
    invoicingCompany: !isInvoicingCompanyValid(meta.invoicingCompany) ? 'Invoicing company is required' : undefined,
    year: !meta.year ? 'Year of production is required' : undefined,
    country: !meta.country || !meta.country.length ? 'Country of origin is required' : undefined,
    aspectRatio: !isValidField('aspectRatio', meta, fieldSet) ? 'Aspect Ratio is required' : undefined,
    runtimeInSeconds: () => getRuntimeValidationError(meta, calendars),
    language: !meta.language ? 'Language is required' : undefined,
    season: !isValidField('season', meta, fieldSet) ? 'Season number is required' : undefined,
    seasonTitle: !isValidField('seasonTitle', meta, fieldSet) ? 'Season/Series Title is required' : undefined,
    eidr: (meta.eidr || '').length && !isEidrValid(meta.eidr) ? 'Invalid EIDR identifier' : undefined,
    requiredRating: !isRequiredRatingValid(meta) ? 'Requested Classification must be 18 or R18.' : undefined,
    genre: () => {
      if (!isValidFieldAndHasItems('genre', meta, fieldSet)) return 'Genre is required';
      if (!isGenreValid(meta)) return 'Genre must include Adult.';
      return undefined;
    },
    version: !isValidGeneralConditionalField('version', meta, fieldSet) ? 'Required' : undefined,
    numberOfLocations: !isValidField('numberOfLocations', meta, fieldSet) ? 'Polled locations (number of venues) is required' : undefined,
    videoIdentical: !isValidField('videoIdentical', meta, fieldSet) ? 'Please select an option' : undefined,
    preschool: !isValidField('preschool', meta, fieldSet) ? 'Please select an option' : undefined,
    modernFilmClassification: !isValidField('modernFilmClassification', meta, fieldSet) ? 'Please select an option' : undefined,
    alsoKnownAs: !isValidField('alsoKnownAs', meta, fieldSet) ? 'Invalid Also Known As field' : undefined,
    isDualRatingCriteriaAllowed: !isValidFieldAndNotFalse('isDualRatingCriteriaAllowed', meta, fieldSet) ? 'Eligibility Criteria is required' : undefined,
    cinemaRegistrationNumber: !isValidFieldAndNotEmpty('cinemaRegistrationNumber', meta, fieldSet) ? 'Registration number of cinema version is required' : undefined,
  };

  if (!options?.withoutRCD) {
    errors.requiredClassificationDueDate = getRCDValidationError(meta.requiredClassificationDueDate, meta, { minClassificationDays });
  }

  if (!options?.excludeRebookingFields) {
    if (isRequiredField('releaseDate', { fieldSet }) || meta.releaseDate) {
      if (!isValidField('releaseDate', meta, fieldSet)) {
        errors.releaseDate = 'Release date is required';
      }

      if (!isReleaseDateValid(meta.releaseDate, meta.requestedClassificationDueDate)) {
        errors.releaseDate = 'Should be after the selected offer date';
      }
    }
  }

  // Remove episode specific validation fields
  if (meta?.productPath?.includes('Episodic')) {
    ['director', 'runtimeInSeconds', 'version'].forEach((field) => {
      errors[field] = undefined;
    });
  }

  // Remove bonus material specific validation fields
  if (meta?.productPath?.includes('Bonus Material')) {
    ['runtimeInSeconds'].forEach((field) => {
      errors[field] = undefined;
    });
  }

  if (Array.isArray(updatableMetaFields)) {
    Object.keys(errors).forEach(fieldName => {
      if (!updatableMetaFields.includes(fieldName)) {
        errors[fieldName] = undefined;
      }
    });
  }
  return errors;
};

export const episodeMetaValidation = (meta = {}, { fieldSet = {}, updatableMetaFields = [] } = {}) => {
  const errors = {
    director: !meta.director ? 'Director is required' : undefined,
    runtimeInSeconds: () => getRuntimeValidationError(meta),
    episode: !isValidField('episode', meta, fieldSet) ? 'Episode number is required' : undefined,
    episodeTitle: !isValidField('episodeTitle', meta, fieldSet) ? 'Episode Title is required' : undefined,
    //version: !isValidGeneralConditionalField('version', meta, fieldSet) ? 'Version is required' : undefined,
  };

  if (Array.isArray(updatableMetaFields)) {
    Object.keys(errors).forEach(fieldName => {
      if (!updatableMetaFields.includes(fieldName)) {
        errors[fieldName] = undefined;
      }
    });
  }
  return errors;
};

export const bonusMaterialMetaValidation = (meta = {}, { updatableMetaFields = [] } = {}) => {
  const errors = {
    additionalTitle: !meta.additionalTitle ? 'Additional title is required' : undefined,
    runtimeInSeconds: () => getRuntimeValidationError(meta),
  };

  if (Array.isArray(updatableMetaFields)) {
    Object.keys(errors).forEach(fieldName => {
      if (!updatableMetaFields.includes(fieldName)) {
        errors[fieldName] = undefined;
      }
    });
  }
  return errors;
};

export const getValidationValue = (validation, field) => (
  typeof validation[field] === 'function' ? validation[field]() : validation[field]
);

export const formatMinutes = (minutes) => {
  const hours = `${minutes / 60 > 0 ? Math.floor(minutes / 60) : 0}`.padStart(2, '0');
  const mins = `${minutes % 60}`.padStart(2, '0');
  return `${hours}:${mins}`;
};
// export const formatSeconds = (seconds) => {
//   const duration = moment.duration(seconds, 'seconds');
//   const hours = duration.hours();
//   const mins = `${duration.minutes()}`.padStart(2, '0');
//   // const secs = `${duration.seconds()}`.padStart(2, '0');
//   return `${hours}h ${mins}min`;
// };

const skipBase = ['productParams', 'dCode', 'client', 'companyProfile'];
const skipV1 = [...skipBase, 'videoIdentical', 'preschool', 'modernFilmClassification', 'numberOfLocations'];
const skipV2 = [...skipBase, 'submissionRating'];
const dateFields = ['requiredClassificationDueDate', 'releaseDate', 'publicationDate'];
const notPrimitiveFields = ['genre', 'country', 'additionalLanguages'];

export const isMetaChanged = (meta, nextMeta, skip = skipV1) => {
  const noChange = Object.keys(nextMeta).reduce((notChanged, dataKey) => {
    if (skip.includes(dataKey)) {
      return notChanged;
    }
    if (dateFields.includes(dataKey)) {
      return notChanged && isSameDay(meta[dataKey], nextMeta[dataKey]);
    }
    if (notPrimitiveFields.includes(dataKey)) {
      return notChanged && isEqual(meta[dataKey], nextMeta[dataKey]);
    }
    return notChanged && meta[dataKey] === nextMeta[dataKey];
  }, true);
  return !noChange;
};

export const isMetaChangedV2 = (meta, nextMeta) => isMetaChanged(meta, nextMeta, skipV2);

export const getChangedMetaFields = (meta, nextMeta, skip = skipV1) => {
  const changedMeta = {};
  Object.keys(nextMeta).forEach(dataKey => {
    if (!skip.includes(dataKey)) {
      if (dateFields.includes(dataKey)) {
        if (!isSameDay(meta[dataKey], nextMeta[dataKey])) {
          changedMeta[dataKey] = nextMeta[dataKey];
        }
      } else if (notPrimitiveFields.includes(dataKey)) {
        if (!isEqual(meta[dataKey], nextMeta[dataKey])) {
          changedMeta[dataKey] = nextMeta[dataKey];
        }
      } else if (meta[dataKey] !== nextMeta[dataKey]) {
        changedMeta[dataKey] = nextMeta[dataKey];
      }
    }
  });
  return changedMeta;
};

export const getChangedMetaFieldsV2 = (meta, nextMeta) => getChangedMetaFields(meta, nextMeta, skipV2);

export const translateSubmissionStatus = status => WORK_ORDER_TAG_DICTIONARY[status] || changeCaseConstantToSentence(status);

const flattenProductTree = (productTree) => {
  const flatProductTree = flatten(productTree);
  return Object.keys(flatProductTree)
    .filter(item => item.includes('isLeaf'))
    .map(item => item.replace(/\.isLeaf/, '').replace(/\./g, '/'));
};

export const isCompleteProductPath = (productPath, productTree) => {
  if (!productPath || !productTree) return false;

  const products = flattenProductTree(productTree);
  if (products.includes(productPath)) return true;
  return false;
};

export const SUBMISSION_RATING_MAP = {
  true: 'yes',
  false: 'no',
  yes: 'yes',
  no: 'no',
};

export const SUBMISSION_RATING_REVERSE_MAP = {
  yes: true,
  no: false,
  true: true,
  false: false,
};

export const getLastSubmission = (submissions) => submissions[submissions.length - 1];

export const getLastEpisodicSubmission = (submissions) => {
  const episodicWorkOrders = submissions.filter(w => has(w, 'meta.episode')) || [];
  return orderBy(episodicWorkOrders, 'meta.episode', 'desc')[0];
};

export const getRebookResultValue = (keyName, oldValue, newValue) => {
  if (![null, undefined].includes(oldValue)) {
    if (isBoolean(newValue)) return `${keyName} has been changed from ${BOOL_TO_TEXT[oldValue]} to ${BOOL_TO_TEXT[newValue]}`;
    if ([null, undefined].includes(newValue)) return `${keyName} previously set to ${moment(oldValue, moment.ISO_8601, true).isValid() ? displayFormatDate(oldValue) : oldValue} has been removed`;
    if (moment(newValue, moment.ISO_8601, true).isValid()) return `${keyName} has been changed from ${displayFormatDate(oldValue)} to ${displayFormatDate(newValue)}`;
    return `${keyName} has been changed from ${oldValue} to ${newValue}`;
  }

  if (isBoolean(newValue)) return `${keyName} has been changed to ${BOOL_TO_TEXT[newValue]}`;
  if ([null, undefined].includes(newValue)) return `${keyName} has been removed`;
  if (moment(newValue, moment.ISO_8601, true).isValid()) return `${keyName} has been changed to ${displayFormatDate(newValue)}`;
  return `${keyName} has been changed to ${newValue}`;
};

export const getFinanceCheckError = (trustedClient, overdue, creditLimit = 0) => {
  const isTrustedClientBlocked = trustedClient && overdue >= creditLimit;
  const isUntrustedClientBlocked = !trustedClient && overdue;

  if (isTrustedClientBlocked || isUntrustedClientBlocked) {
    return `The Invoicing company has an outstanding balance of £${overdue} therefore the order cannot proceed.`;
  }

  return undefined;
};

export const getNumberOfLocationOptions = (obj, path) => {
  const list = get(obj, path, 'N/A');
  return (list === 'N/A' ? ['N/A'] : list).map(option => ({ value: option, label: option }));
};

export const escapeResubmissionNote = (data) => {
  const { cuts } = data;
  for (const key in cuts) {
    if (Object.keys(cuts).includes(key)) {
      const value = cuts[key];
      value.clientComment = escapeQuotationMarks(value?.clientComment);
      value.note = escapeQuotationMarks(value?.note);
    }
  }
  return data;
};

export const isComplianceReviewPassed = (tags) => !!intersection(tags, COMPLIANCE_REVIEW_PASSED_TAGS).length;

export const omitSubmissionMetaFields = (meta) => {
  const productPath = get(meta, 'productPath', '');
  if (productPath.includes(PRODUCT_PATH_GENERATE_RATING_FROM_CINEMA)) {
    return omit(meta, IGNORED_GENERATE_RATING_FROM_CINEMA_META_FIELDS);
  }
  return omit(meta, IGNORED_DUAL_RATING_META_FIELDS);
};

export const omitDisallowedProductPath = (path, items, isDualRatingEnabled = false) => {
  if (['Home Entertainment.Packaged Media', 'Home Entertainment.Video On Demand'].includes(path) && !isDualRatingEnabled) {
    return omit(items, PRODUCT_PATH_GENERATE_RATING_FROM_CINEMA);
  }
  return items;
};

export const isRCDTooClose = (isMediaDeliveryMissed, rcd) => !isMediaDeliveryMissed && moment(rcd).diff(Date.now(), 'hours') < 24;

/**
 * Returns the asset ids from the submission's asset metadata
 * @param {*} submission The whole submission object
 * @returns An array of ids (number)
 */
export const getSubmissionAssetIds = (submission) => {
  const itemIds = get(submission, 'asset.items', []).map(item => get(item, 'id'));
  return itemIds.filter(id => id);
};

/**
 * Returns the default asset id from the submission's asset metadata
 * @param {*} submission The whole submission object
 * @returns An id of an asset (number)
 */
export const getSubmissionDefaultAssetId = (submission) => {
  const items = get(submission, 'asset.items', []);
  const defaultItem = items.find(item => item.default);
  return defaultItem?.id;
};

/**
 * Returns the encoded entity id
 * @param {*} id The id of the entity (number)
 * @param {*} entity The type of the entity (Asset|Collection)
 * @returns An encoded id of the entity (string, base64)
 */
export const getEncodedId = (id, entity = 'Asset') => {
  if (!id) return;
  const entityId = `${entity}:VX-${id}`;
  const buffer = Buffer.from(entityId, 'utf-8');
  const encodedId = buffer.toString('base64');
  return encodedId;
};

export const isCinemaFeature = (productPath) => productPath?.includes('Feature') && productPath?.includes('Cinema');

export const isHomeEnt = (productPath = '') => productPath.startsWith('Home Entertainment/');

export const parseSubmissionArrayField = (row, key) => {
  const field = get(row, key);
  if (!field) return undefined;
  if (Array.isArray(field)) {
    return field.join(', ');
  }
  return field;
};

export const isMetadataFieldUpdatable = (updatableFields, fieldName) => {
  if (!Array.isArray(updatableFields)) return false;
  return updatableFields.includes(fieldName);
};

export const getMetaFormTitle = (meta) => {
  const isBatch = meta?.productParams?.batch;
  const isEpisodicProduct = meta?.productPath?.includes('Episodic');
  const isBonusMaterialProduct = meta?.productPath?.includes('Bonus Material');

  if (isBatch && isEpisodicProduct) return 'Episodic submission global data';
  if (isBatch && isBonusMaterialProduct) return 'Bonus material submission global data';

  return 'Submission data';
};

export const getValidMetaData = (meta, productParams) => {
  const nextMeta = {
    ...meta,
  };

  if (!productParams) return nextMeta;

  Object.keys(nextMeta).forEach((key) => {
    const fieldValidation = productParams[key];

    if (nextMeta[key] && key in productParams && !['required', 'optional', true].includes(fieldValidation)) {
      // TODO: Remove log after testing on UAT.
      console.info(`setting ${key} to undefined in meta because it is not needed`);
      // This has to be undefined, cannot be null, or no offers will be returned.
      nextMeta[key] = undefined;
    }
  });

  return nextMeta;
};
