export const actionTypes = {
  START_FETCH_CLIENT_ORDERS: 'START_FETCH_CLIENT_ORDERS',
  RESOLVE_FETCH_CLIENT_ORDERS: 'RESOLVE_FETCH_CLIENT_ORDERS',
  REJECT_FETCH_CLIENT_ORDERS: 'REJECT_FETCH_CLIENT_ORDERS',
  START_FETCH_CLIENT_ORDER: 'START_FETCH_CLIENT_ORDER',
  RESOLVE_FETCH_CLIENT_ORDER: 'RESOLVE_FETCH_CLIENT_ORDER',
  REJECT_FETCH_CLIENT_ORDER: 'REJECT_FETCH_CLIENT_ORDER',
  CLEAR_CLIENT_ORDERS: 'CLEAR_CLIENT_ORDERS',
  GET_TABLE_PREFERENCES_SUCCESS: 'co-GET_TABLE_PREFERENCES_SUCCESS',
  RESET_TABLE_PREFERENCES: 'co-RESET_TABLE_PREFERENCES',
  SET_TABLE_PREFERENCES: 'co-SET_TABLE_PREFERENCES',
  SIGNAL_START: 'co-SIGNAL_START',
  SIGNAL_SUCCESS: 'co-SIGNAL_SUCCESS',
  SIGNAL_FAILURE: 'co-SIGNAL_FAILURE',
  TOGGLE_SHOW_DELETED_ITEMS: 'co-TOGGLE_SHOW_DELETED_ITEMS',
};

export const startFetchClientOrders = () => ({ type: actionTypes.START_FETCH_CLIENT_ORDERS });
export const resolveFetchClientOrders = (clientOrders, count) => ({ type: actionTypes.RESOLVE_FETCH_CLIENT_ORDERS, payload: { clientOrders, count } });
export const rejectFetchClientOrders = error => ({ type: actionTypes.REJECT_FETCH_CLIENT_ORDERS, payload: error });
export const startFetchClientOrder = () => ({ type: actionTypes.START_FETCH_CLIENT_ORDER });
export const resolveFetchClientOrder = clientOrder => ({ type: actionTypes.RESOLVE_FETCH_CLIENT_ORDER, payload: clientOrder });
export const rejectFetchClientOrder = error => ({ type: actionTypes.REJECT_FETCH_CLIENT_ORDER, payload: error });
export const clearClientOrders = () => ({ type: actionTypes.CLEAR_CLIENT_ORDERS });

export const getTablePreferencesSuccess = result => ({ type: actionTypes.GET_TABLE_PREFERENCES_SUCCESS, payload: result });

export const resetTablePreferences = () => ({ type: actionTypes.RESET_TABLE_PREFERENCES });

export const setTablePreferences = payload => ({ type: actionTypes.SET_TABLE_PREFERENCES, payload });

export const signalStart = (clientOrderId, signalName, inputData, options) => ({
  type: actionTypes.SIGNAL_START,
  payload: { clientOrderId, signalName, inputData, options },
});
export const signalSuccess = () => ({
  type: actionTypes.SIGNAL_SUCCESS,
});
export const signalFailure = () => ({
  type: actionTypes.SIGNAL_FAILURE,
});
export const toggleShowDeletedItems = () => ({
  type: actionTypes.TOGGLE_SHOW_DELETED_ITEMS,
});
