import { actionTypes } from '../actions/client-orders';
import { transformClientOrder } from '../utils';

const tableColums = [
  { columnName: 'id', width: 200 },
  { columnName: 'workOrders.id', width: 200 },
  { columnName: 'workOrders.meta.title', width: 180 },
  { columnName: 'user.fullName', width: 120 },
  { columnName: 'company.companyName', width: 180 },
  { columnName: 'workOrders.meta.productPath', width: 245 },
  { columnName: 'workOrderCount', width: 150 },
  { columnName: 'workOrderSummary', width: 150 },
  { columnName: 'finance.invoicing.nettoAmount', width: 150 },
  { columnName: 'ratingType', width: 150 },
  { columnName: 'tags', width: 180 },
  { columnName: 'createdAt', width: 150 },
  { columnName: 'updatedAt', width: 150 },
  { columnName: 'paymentSummary', width: 150 },
  { columnName: 'options', width: 100 },
];

export const clientOrdersDefaultState = {
  rows: [],
  isFetching: false,
  details: {},
  count: 0,
  error: {
    message: '',
    status: null,
  },
  tablePreferences: {
    hiddenColumns: [
      'id',
      'workOrders.id',
      'updatedAt',
    ],
    columnOrder: tableColums.map(({ columnName }) => columnName),
    columnWidths: tableColums,
    rowsPerPage: 10,
  },
  showDeletedItems: false,
  featured: [],
};

// eslint-disable-next-line default-param-last
export const reducer = (state = clientOrdersDefaultState, { type, payload }) => {
  switch (type) {
    case actionTypes.START_FETCH_CLIENT_ORDERS:
      return {
        ...state,
        isFetching: true,
        // rows: [],
        error: {},
      };
    case actionTypes.RESOLVE_FETCH_CLIENT_ORDERS:
      return {
        ...state,
        isFetching: false,
        rows: payload.clientOrders.map(transformClientOrder),
        count: payload.count,
      };
    case actionTypes.REJECT_FETCH_CLIENT_ORDERS:
      return {
        ...state,
        isFetching: false,
        rows: [],
        error: payload,
      };
    case actionTypes.START_FETCH_CLIENT_ORDER:
      return {
        ...state,
        isFetching: true,
        // details: {},
        error: {},
      };
    case actionTypes.RESOLVE_FETCH_CLIENT_ORDER:
      return {
        ...state,
        isFetching: false,
        details: transformClientOrder(payload),
      };
    case actionTypes.REJECT_FETCH_CLIENT_ORDER:
      return {
        ...state,
        isFetching: false,
        details: {},
        error: payload,
      };
    case actionTypes.CLEAR_CLIENT_ORDERS:
      return clientOrdersDefaultState;
    case actionTypes.RESET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: clientOrdersDefaultState.tablePreferences,
      };
    }
    case actionTypes.GET_TABLE_PREFERENCES_SUCCESS: {
      return {
        ...state,
        tablePreferences: payload,
      };
    }
    case actionTypes.SET_TABLE_PREFERENCES: {
      return {
        ...state,
        tablePreferences: { ...state.tablePreferences, [payload.key]: payload.value },
      };
    }
    case actionTypes.TOGGLE_SHOW_DELETED_ITEMS: {
      return {
        ...state,
        showDeletedItems: !state.showDeletedItems,
      };
    }
    default:
      return state;
  }
};

export default reducer;
