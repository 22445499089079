import getConfig from 'next/config';
import { invoke } from './api';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.apiUrl || publicRuntimeConfig.apiUrl;
const taskProxyUrl = `${apiUrl}/task`;

console.log('TASK PROXY URI:', taskProxyUrl); // eslint-disable-line no-console

const api = {
  get: invoke(taskProxyUrl, 'GET'),
  post: invoke(taskProxyUrl, 'POST'),
  put: invoke(taskProxyUrl, 'PUT'),
  patch: invoke(taskProxyUrl, 'PATCH'),
  delete: invoke(taskProxyUrl, 'DELETE'),
};

export const getTaskPage = payload => api.post('/task/page', { body: payload, query: { 'include-workorder': true } });

export const getLatestTask = payload => api.post('/task/latest', { body: payload, query: { 'include-workorder': true } });

export const resolveTaskSuccess = (id, payload) => api.post(`/task/${id}/resolve`, { body: payload });

export const updateTask = (id, payload) => api.post(`/task/${id}/update`, { body: payload });

export const getTask = id => api.get(`/tasks/${id}?include-workorder=true`);

export const getWorkOrderPage = (payload, { includeTask = 'open' }) => (
  api.post('/work-order/page', { body: payload, query: { 'include-task': includeTask } })
);

export const getLatestWorkOrder = payload => api.post('/work-order/latest', { body: payload });

export const getWorkOrderJournal = (id) => api.get(`/work-order/${id}/journal`);

export const addWorkOrder = payload => api.post('/work-order', { body: payload });

export const addClientOrder = payload => api.put('/client-order', { body: payload });

export const getWorkOrder = (id, { allowDraft = false, includeTask = 'open' }) => (
  api.get(`/work-order/${id}`, { query: { 'include-task': includeTask, 'allow-draft': allowDraft ? 'yes' : 'no' } })
);

export const getWorkOrderLastUpdate = (id, { allowDraft = false }) => api.get(`/work-order/${id}/last-update`, { query: { 'allow-draft': allowDraft ? 'yes' : 'no' } });

export const getClientOrders = payload => api.post('/client-order', { body: payload });

export const getLatestClientOrders = payload => api.post('/client-order/latest', { body: payload });

export const getClientOrder = (id, filters, { allowDraft = false, includeWorkOrders = true }) => api.post(`/client-order/${id}`, { body: filters, query: { 'allow-draft': allowDraft ? 'yes' : 'no', 'include-work-orders': includeWorkOrders ? 'yes' : 'no' } });

export const getLatestClientOrder = (id, filters) => api.post(`/client-order/${id}/latest`, { body: filters });

export const getCertificate = (id, type, version) => api.get(`/work-order/${id}/certificate`, { query: { type, version }, responseType: 'arraybuffer', raw: true });

export const getCertificateList = (id) => api.get(`/work-order/${id}/certificate/list`);

export const getVideoAssetList = id => api.get(`/work-order/${id}/videoAsset/list`);

export const getVideoAsseSignedUrl = (id, fileKey, bucket) => api.post(`/work-order/${id}/videoAsset/getSignedUrl`, { body: { fileKey, bucket } });

export const assignTask = (id, payload) => api.patch(`/task/${id}`, { body: payload });

export const signalWorkOrder = (workOrderId, signalName, inputData, { allowDraft = false } = {}) => (
  api.post(`/work-order/${workOrderId}/signal/${signalName}`, { body: inputData, query: { 'allow-draft': allowDraft ? 'yes' : 'no' } })
);

export const signalClientOrder = (clientOrderId, signalName, inputData, { allowDraft = false } = {}) => (
  api.post(`/client-order/${clientOrderId}/signal/${signalName}`, { body: inputData, query: { 'allow-draft': allowDraft ? 'yes' : 'no' } })
);

export const createEpisodeSubmissions = (clientOrderId, inputData) => (
  api.post(`/client-order/${clientOrderId}/episodic`, { body: inputData })
);

export const getWorkflowHistory = workOrderId => api.get(`/work-order/${workOrderId}/history`);

export const restartWorkflowHard = (id, payload) => api.post(`/work-order/${id}/restartHard`, { body: payload });

export const getReport = (workOrderId, reportId) => api.get(`/work-order/${workOrderId}/report`, { query: { reportId } });

export const getReports = (workOrderId) => api.get(`/work-order/${workOrderId}/report`);

export const addClientOrderV2 = () => api.put('client-order/v2', {});

export const addWorkOrderV2 = payload => api.post('/work-order/v2', { body: payload });

export const addEpisodicWorkOrderV2 = (clientOrderId, payload) => api.post(`/client-order/v2/${clientOrderId}/submissions`, { body: payload });

export const rebook = payload => api.post('/work-order/v2/rebook', { body: payload });

export const getWorkflowVersionById = (workOrderId) => api.get(`/work-order/v2/${workOrderId}/workflowVersion`);
